import React from 'react';
import { useEffectOnce } from 'react-use';
import { useClub } from '~/features/club';
import { P190LoginPage as P190LoginPageType } from '~/lib/data-contract';
import { DynamicBlockList } from '~/templates/blocks';
import { usePage } from '~/templates/pages';

export const P190LoginPage = () => {
    const { toggleSignInActive } = useClub();
    const { pageElements = [] } = usePage<P190LoginPageType>();

    useEffectOnce(() => {
        toggleSignInActive(true);
    });

    return <DynamicBlockList elements={pageElements} />;
};
